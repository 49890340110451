<template>
  <div
    class="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50"
    v-if="!socketConnexionStatus"
  >
    <div
      class="bg-skin-fill-2 flex flex-col items-center justify-center gap-4 rounded-md px-10 py-6"
    >
      <span class="text-center">Vous n'êtes pas connecté.<br />Merci de patienter.</span>
    </div>
  </div>
  <slot />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAppStore } from '~/stores/app';

const { socketConnexionStatus } = storeToRefs(useAppStore());
</script>
