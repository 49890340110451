<template>
  <div
    class="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50"
    v-if="needWaitServerUpgrade || needRefreshClient"
  >
    <div
      class="bg-skin-fill-2 flex flex-col items-center justify-center gap-4 rounded-md px-10 py-6"
    >
      <template v-if="needRefreshClient">
        <span>Une nouvelle version est disponible</span
        ><Button @click="refreshPage">Actualiser</Button>
      </template>
      <template v-else-if="needWaitServerUpgrade">
        <span>Une mise à jour est en cours. Merci de patienter</span>
      </template>
    </div>
  </div>
  <slot />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAppStore } from '~/stores/app';

const { needRefreshClient, needWaitServerUpgrade } = storeToRefs(useAppStore());

const refreshPage = () => {
  window.location.reload();
};
</script>
